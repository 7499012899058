try {
  const _dp = Object.defineProperty
  _dp(Object, 'defineProperty', {
    value: function () {
      try {
        return _dp.apply(this, arguments)
      } catch (e) {
        console.warn(e)
      }
    }
  })
} catch (_e) {
  // pass
}

// import('./helpers/widgetDetector').then(({ default: widgetDetector }) => {
//   widgetDetector.checkWidgetStatus()
// })

import(/* webpackChunkName: "triplabot" */ './triplabot').then(({ default: bootstrap }) => {
  bootstrap()
})
